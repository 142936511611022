import React from 'react';
import Gist from 'react-gist';
import ExternalLink from "../../../../src/components/ExternalLink";
{
  /* <ExternalLink title="" href=""></ExternalLink> */

  /* <Gist id='' file='' /> */
}
export default {
  React,
  Gist,
  ExternalLink
  /* <ExternalLink title="" href=""></ExternalLink> */

  /* <Gist id='' file='' /> */

};