import React from 'react';
import ExternalLink from "../../../../src/components/ExternalLink";
{
  /* <ExternalLink title="" href=""></ExternalLink> */
}
export default {
  React,
  ExternalLink
  /* <ExternalLink title="" href=""></ExternalLink> */

};