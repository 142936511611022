import React from 'react'

// Sample: <ExternalLink title="" href="" />
const ExternalLink = ({ href, title, children, ...props }) => {
  return (
    <>
      <a {...props} target="_blank" rel="noreferrer" title={title} href={href}>
        {children}
      </a>
    </>
  )
}

export default ExternalLink
