import React from 'react'
import Highlight, { defaultProps } from 'prism-react-renderer'
import theme from 'prism-react-renderer/themes/nightOwlLight'

const preStyle = {
  background: '1e1e1e',
  padding: '1rem 1.5rem',
  borderRadius: '0.25rem',
  margin: '3rem 0',
  fontFamily: `Courier New', Courier, monospace`,
  overflowX: 'scroll',
}

const Prism = props => {
  const className = props.children.props.className || ''
  const language = className === '' ? 'default' : className.split('-')[1]

  return (
    <Highlight
      {...defaultProps}
      code={props.children.props.children.trim()}
      language={language}
      theme={theme}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => {
        return (
          <div style={{ position: 'relative' }}>
            <pre className={className} style={(style, preStyle)}>
              <div className="code-tab">{language}</div>
              {tokens.map((line, i) => (
                <div {...getLineProps({ line, key: i })}>
                  {line.map((token, key) => (
                    <span {...getTokenProps({ token, key })} />
                  ))}
                </div>
              ))}
            </pre>
          </div>
        )
      }}
    </Highlight>
  )
}

export default Prism
