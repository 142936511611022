import React from 'react'
import { MDXProvider } from '@mdx-js/react'

import Prism from './src/components/Prism'

const components = {
  pre: Prism,
}

export const wrapMDX = ({ element }) => {
  return <MDXProvider components={components}>{element}</MDXProvider>
}
