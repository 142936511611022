// CSS
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/styles.css'

// JS
import 'bootstrap/dist/js/bootstrap.min.js'
// import '@popperjs/core/dist/umd/popper.min.js'
import 'popper.js/dist/popper.min'

// Prism
import { wrapMDX } from './root-prism'
export const wrapRootElement = wrapMDX
